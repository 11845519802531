import Http from './HttpService';

import ServerConfig from './ServerConfig';

const emailTemplatesAPIHost = ServerConfig.emailTemplatesAPIHost();

class EmailTemplateService {
    static async list() {
        try {
            const response = await Http.get(`${emailTemplatesAPIHost}/email-templates`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async create(payload) {
        try {
            const response = await Http.post(`${emailTemplatesAPIHost}/email-templates`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async get(id) {
        try {
            const response = await Http.get(`${emailTemplatesAPIHost}/email-templates/${id}`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async update(id, payload) {
        try {
            const response = await Http.put(`${emailTemplatesAPIHost}/email-templates/${id}`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async remove(id) {
        try {
            const response = await Http.delete(`${emailTemplatesAPIHost}/email-templates/${id}`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async duplicate(payload) {
        try {
            const response = await Http.post(`${emailTemplatesAPIHost}/email-templates/duplicate`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async publish(payload) {
        try {
            const response = await Http.post(`${emailTemplatesAPIHost}/email-templates/publish`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
}

export default EmailTemplateService;
