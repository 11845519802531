import axios from 'axios';
class Http {
    static get(url) {
        return axios.get(url);
    }

    static post(url, body) {
        return axios.post(url, body);
    }

    static put(url, body) {
        return axios.put(url, body);
    }

    static delete(url) {
        return axios.delete(url);
    }
}

export default Http;
