import React from 'react';
import ReactDOM from 'react-dom';

import './interceptor';

import App from 'components/App/App';
import UserService from 'services/UserService';

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/sass/easypay.scss?v=1.2.0';
import 'assets/css/demo.css';
import 'assets/css/pe-icon-7-stroke.css';

const renderApp = () =>
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );

UserService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
