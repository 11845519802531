import Http from './HttpService';

import ServerConfig from './ServerConfig';

const templateTypesAPIHost = ServerConfig.templateTypesAPIHost();

class TemplateTypeService {
    static async list() {
        try {
            const response = await Http.get(`${templateTypesAPIHost}/template-types`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async create(payload) {
        try {
            const response = await Http.post(`${templateTypesAPIHost}/template-types`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async get(id) {
        try {
            const response = await Http.get(`${templateTypesAPIHost}/template-types/${id}`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async update(id, payload) {
        try {
            const response = await Http.put(`${templateTypesAPIHost}/template-types/${id}`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async remove(id) {
        try {
            const response = await Http.delete(`${templateTypesAPIHost}/template-types/${id}`);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
    static async duplicate(payload) {
        try {
            const response = await Http.post(`${templateTypesAPIHost}/template-types/duplicate`, payload);
            return response.data;
        } catch (error) {
            const {
                response: { data },
            } = error;
            throw data;
        }
    }
}

export default TemplateTypeService;
