import React, { createContext, useReducer, useContext, useCallback } from 'react';

// Services
import TemplateTypeService from 'services/TemplateTypeService';
import EmailTemplateService from 'services/EmailTemplateService';

import { noop } from 'lodash';

import { AppContextProps, AppState } from './types';
import reducer from './reducer';
import actions from './actions';

const initialState: AppState = {
    loading: false,
};

const actionList = {
    clear: noop,
    fetchTemplateTypes: noop,
    fetchEmailTemplates: noop,
};

const AppContext = createContext<AppContextProps>({
    state: initialState,
    dispatch: noop,
    actions: actionList,
});

const AppProvider = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const createAction = (type: string, payload: any) => ({
        type,
        payload,
    });

    const clear = useCallback(() => {
        dispatch(createAction(actions.CLEAR, null));
    }, []);

    const fetchTemplateTypes = useCallback(async () => {
        const result = await TemplateTypeService.list();
        dispatch(createAction(actions.FETCH_TEMPLATE_TYPES, result));
    }, []);

    const fetchEmailTemplates = useCallback(async () => {
        const result = await EmailTemplateService.list();
        dispatch(createAction(actions.FETCH_EMAIL_TEMPLATES, result));
    }, []);

    return (
        <AppContext.Provider
            value={{
                state,
                dispatch,
                actions: {
                    clear,
                    fetchTemplateTypes,
                    fetchEmailTemplates,
                },
            }}
        >
            {state.loading && <div>Loading...</div>}
            {props.children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, useAppContext };
