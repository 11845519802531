const BASE_URL = window.location.origin;

class ServerConfig {
    defaultAPIHost() {
        return BASE_URL.includes('http://localhost')
            ? process.env.REACT_APP_LOCAL_API_URL
            : process.env.REACT_APP_LAMBDA_API_URL;
    }
    templateTypesAPIHost() {
        return BASE_URL.includes('http://localhost')
            ? process.env.REACT_APP_TEMPLATE_TYPES_API_URL
            : process.env.REACT_APP_TEMPLATE_TYPES_API_URL;
    }
    emailTemplatesAPIHost() {
        return BASE_URL.includes('http://localhost')
            ? process.env.REACT_APP_EMAIL_TEMPLATES_API_URL
            : process.env.REACT_APP_EMAIL_TEMPLATES_API_URL;
    }
}

export default new ServerConfig();
