import axios from 'axios';
import UserService from './services/UserService';

axios.interceptors.request.use(
    function (config) {
        const token = UserService.getToken();
        if (!token) return config;
        config.headers.authorization = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);
