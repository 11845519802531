import actions from './actions';
import { AppState } from './types.d';

export default (state: AppState, action: any) => {
    switch (action.type) {
        case actions.LOADING:
            return { ...state, loading: action.payload };
        case actions.FETCH_TEMPLATE_TYPES:
            return { ...state, templateTypeList: action.payload };
        case actions.FETCH_EMAIL_TEMPLATES:
            return { ...state, emailTemplateList: action.payload };
        default:
            return state;
    }
};
