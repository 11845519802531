import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { AppProvider } from 'context/app';

const AdminLayout = lazy(() => import('layouts/Admin'));

const App: React.FC = () => (
  <AppProvider>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {/* <Route path="/auth" render={(props: any) => <AuthLayout {...props} />} /> */}
          <Route path="/" render={(props: any) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </AppProvider>
);

export default App;
